import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PatientsComponent} from "./components/patients/patients.component";
import {AppointmentsComponent} from "./components/appointments/appointments.component";
import {DetailsComponent} from "./components/appointments/details/details.component";

const routes: Routes = [
  {
    path: 'patients',
    component: PatientsComponent
  },
  {
    path: 'appointments',
    component: AppointmentsComponent
  },
  {
    path: 'appointments/detail/:id',
    component: DetailsComponent
  },
  {
    path: '**',
    redirectTo: 'patients'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
