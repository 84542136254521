import {Component, OnInit} from '@angular/core';
import {ClinicsService} from "../../services/clinics.service";
import {ApiResponse, Clinic} from "../../models/clinic.model";

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss']
})
export class AppointmentsComponent implements OnInit{
  public clinics: Clinic[] | undefined = [];

  constructor(private clinicsService: ClinicsService) {
  }

  ngOnInit() {
    this.getAllClinic();
  }

  getAllClinic() {
    this.clinicsService.getClinics().subscribe((res: Clinic[] | undefined) => {
      this.clinics = res;
    });
  }

}
