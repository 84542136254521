import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/header/header.component';
import {PatientsComponent} from './components/patients/patients.component';
import {AppointmentsComponent} from './components/appointments/appointments.component';
import {NgxSpinnerModule} from "ngx-spinner";
import {HttpClientModule} from "@angular/common/http";
import {ToastrModule} from "ngx-toastr";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {DetailsComponent} from './components/appointments/details/details.component';
import {GetCustomFieldPipe} from "./components/appointments/details/get-custom-field.pipe";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSortModule} from "@angular/material/sort";
import {PatientTableComponent} from './components/appointments/details/patient-table/patient-table.component';
import {MatCheckboxModule} from "@angular/material/checkbox";

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        PatientsComponent,
        AppointmentsComponent,
        DetailsComponent,
        GetCustomFieldPipe,
        PatientTableComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgxSpinnerModule.forRoot(),
        ToastrModule.forRoot(),
        NgxDatatableModule,
        ReactiveFormsModule,
        FormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule
    ],
    providers: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
