import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getCustomFieldPipe'
})
export class GetCustomFieldPipe implements PipeTransform {

  transform(value: any, customField: string): string {
    if (!value) return value;
    return value.custom_fields.find((cf: { custom_field_name: string; }) => cf.custom_field_name === customField)?.value;
  }

}
