import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {map, Observable} from "rxjs";
import {ApiResponse, Clinic} from "../models/clinic.model";
import {Patient} from "../models/patient.model";

@Injectable({
  providedIn: 'root'
})
export class PatientsService {
  public resourceUrl = '/patient'

  constructor(private httpClient: HttpClient) {
    this.resourceUrl = environment.backendUrl + this.resourceUrl;
  }

  getPatients(doctorId: number, isDoctorPatient: boolean, country: string): Observable<Patient[]> {
    return this.httpClient.get(this.resourceUrl + `/${doctorId}/${isDoctorPatient}?country=${country}`, {observe: 'response'})
      .pipe(map((res: any) => res.body.data));
  }

  importPatient(file: File) {
    const formData = new FormData();
    formData.set('file', file);
    return this.httpClient.post(this.resourceUrl, formData);
  }
}
