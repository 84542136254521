import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {map, Observable} from "rxjs";
import {ApiResponse, Clinic} from "../models/clinic.model";
import {Patient} from "../models/patient.model";

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {
  public resourceUrl = '/appointment'

  constructor(private httpClient: HttpClient) {
    this.resourceUrl = environment.backendUrl + this.resourceUrl;
  }

  createAppointment(body: any) {
    return this.httpClient.post(this.resourceUrl, body);
  }

  deleteAppointment(doctorId: number, clinicId: number) {
    return this.httpClient.delete(this.resourceUrl + `/${doctorId}/${clinicId}`);
  }
}
