import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {map, Observable} from "rxjs";
import {ApiResponse, Clinic} from "../models/clinic.model";
import {Doctor} from "../models/doctor.model";

@Injectable({
  providedIn: 'root'
})
export class DoctorsService {
  public resourceUrl = '/doctor'

  constructor(private httpClient: HttpClient) {
    this.resourceUrl = environment.backendUrl + this.resourceUrl;
  }

  getDoctors(clinicId: string | null): Observable<Doctor[]> {
    return this.httpClient.get(this.resourceUrl + `/${clinicId}`, {observe: 'response'})
      .pipe(map((res: any) => res.body.data));
  }
}
