import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {map, Observable} from "rxjs";
import {ApiResponse, Clinic} from "../models/clinic.model";

@Injectable({
  providedIn: 'root'
})
export class ClinicsService {
  public resourceUrl = '/clinic'

  constructor(private httpClient: HttpClient) {
    this.resourceUrl = environment.backendUrl + this.resourceUrl;
  }

  getClinics(): Observable<Clinic[] | undefined> {
    return this.httpClient.get<ApiResponse>(this.resourceUrl, {observe: 'response'})
      .pipe(map((res: HttpResponse<ApiResponse>) => res.body?.data));
  }
}
