<div class="row">
  <div class="col-12">
    <hr>
    <h2>Patient Import</h2>
    <hr>
  </div>

  <div class="col-12">
    <div class="mb-3">
      <input accept="text/csv" (change)="onFileSelected($event)" class="form-control" type="file" id="formFile">
    </div>
    <button [disabled]="!selectedFile" class="btn btn-primary" (click)="onUpload()">Upload</button>
  </div>
</div>
