import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort, Sort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Patient } from "../../../../models/patient.model";
import {SelectionModel} from "@angular/cdk/collections";

@Component({
    selector: 'app-patient-table',
    templateUrl: './patient-table.component.html',
    styleUrls: ['./patient-table.component.scss']
})
export class PatientTableComponent implements AfterViewInit {
    public displayedColumns: string[] = ['check', 'first_name', 'phone_number', 'email', 'fb_recorded_date', 'fb_where_do_you_reach_us', 'actions'];

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    @Input() dataSource = new MatTableDataSource<Patient>();
    @Input() actions = false;
    @Input() selectedCountry: string;
    @Output() removeAction$: EventEmitter<Patient> = new EventEmitter<Patient>();
    @Output() selectPatients$: EventEmitter<Patient[]> = new EventEmitter<Patient[]>();


    selection = new SelectionModel<Patient>(true, []);
    lastSelectedIndex: number | null = null;
    shiftPressed: boolean = false;

    constructor() {

        window.addEventListener('keydown', (event) => {
            if (event.key === 'Shift') {
                this.shiftPressed = true;
            }
        });

        window.addEventListener('keyup', (event) => {
            if (event.key === 'Shift') {
                this.shiftPressed = false;
            }
        });
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.sort.sortChange.subscribe((sort: Sort) => {
            this.dataSource.data = [...this.sortCustomFieldsByDate(this.dataSource.data, sort.direction)]
        });
    }

    sortCustomFieldsByDate(data: Patient[], order: string): any[] {
        return data.sort((a, b) => {
            const dateA = new Date(
                a.custom_fields.find((field: any) => field.custom_field_name === 'fb_recorded_date')?.value || 0
            ).getTime();
            const dateB = new Date(
                b.custom_fields.find((field: any) => field.custom_field_name === 'fb_recorded_date')?.value || 0
            ).getTime();

            if (order === 'asc') {
                return dateA - dateB;
            } else {
                return dateB - dateA;
            }
        });
    }

    removeAction(patient: Patient) {
        this.removeAction$.emit(patient);
    }

    onCheckboxChange(event: any, index: number, row: any) {
        if (this.shiftPressed && this.lastSelectedIndex !== null) {
            const start = Math.min(index, this.lastSelectedIndex);
            const end = Math.max(index, this.lastSelectedIndex);

            if (index > this.lastSelectedIndex) {
                for (let i = start; i <= end; i++) {
                    this.selection.select(this.dataSource.data[i]);
                }
            } else {
                for (let i = start; i <= end; i++) {
                    this.selection.deselect(this.dataSource.data[i]);
                }
            }
        } else {
            if (event.checked) {
                this.selection.select(row);
            } else {
                this.selection.deselect(row);
            }
        }
        this.lastSelectedIndex = index;

        this.selectPatients$.emit(this.selection.selected);
    }

    toggleSelectAll(event: any) {
        if (event.checked) {
            this.selection.select(...this.dataSource.data);
            this.selectPatients$.emit(this.selection.selected);
        } else {
            this.selection.clear();
        }
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    isIndeterminate() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected > 0 && numSelected < numRows;
    }
}
