<div class="table-responsive">
    <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="check">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                        (change)="toggleSelectAll($event)"
                        [checked]="isAllSelected()"
                        [indeterminate]="isIndeterminate()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row; let i = index">
                <mat-checkbox
                        (click)="$event.stopPropagation()"
                        [checked]="selection.isSelected(row)"
                        (change)="onCheckboxChange($event, i, row)">
                </mat-checkbox>
            </td>
        </ng-container>

        <!-- First Name Column -->
        <ng-container matColumnDef="first_name">
            <th mat-header-cell *matHeaderCellDef>First Name Last name</th>
            <td mat-cell *matCellDef="let element">{{ element.first_name.en ?  element.first_name.en : element.first_name.tr }} {{ element.last_name.en ? element.last_name.en : element.last_name.tr }}</td>
        </ng-container>

        <!-- Phone Number Column -->
        <ng-container matColumnDef="phone_number">
            <th mat-header-cell *matHeaderCellDef>Phone Number</th>
            <td mat-cell *matCellDef="let element">{{ element.phone_number }}</td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let element">
                {{ element | getCustomFieldPipe: 'email' }}
            </td>
        </ng-container>

        <!-- FB Recorded Date Column -->
        <ng-container matColumnDef="fb_recorded_date">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>FB Recorded Date</th>
            <td mat-cell *matCellDef="let element">
                {{ element | getCustomFieldPipe: 'fb_recorded_date' | date: 'dd.MM.yyyy - HH:mm' }}
            </td>
        </ng-container>

        <!-- FB Where Do You Reach Us Column -->
        <ng-container matColumnDef="fb_where_do_you_reach_us">
            <th mat-header-cell *matHeaderCellDef>FB Where Do You Reach Us</th>
            <td mat-cell *matCellDef="let element">
                {{ element | getCustomFieldPipe: 'fb_where_do_you_reach_us' }}
            </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element">
                <button *ngIf="actions" (click)="removeAction(element)" class="btn btn-sm btn-danger">Remove</button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 50, 100, 500]" [pageSize]="10" showFirstLastButtons></mat-paginator>
</div>
