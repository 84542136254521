import { Component } from '@angular/core';
import {PatientsService} from "../../services/patients.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss']
})
export class PatientsComponent {
  public selectedFile: File | null = null;

  constructor(private patientService: PatientsService,
              private toastr: ToastrService) {

  }

  onUpload() {
    if (this.selectedFile) {
      this.toastr.info('İmport işlemi başladı bittiğinde bilgilendirileceksiniz.');
      this.patientService.importPatient(this.selectedFile).subscribe(res => {
        console.log(res);
        this.toastr.success('Import işlemi başarı ile bitti.')
      }, (err) => {
        console.log(err);
        this.toastr.error(err.error.message);
      });
    }
  }

  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0];
  }
}
