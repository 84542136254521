<div class="row">
  <div class="col-12" *ngIf="clinics">
    <div class="container mt-5">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th>Clinic name</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let clinic of clinics">
          <td>{{ clinic.name.tr ? clinic.name.tr : clinic.name.en }}</td>
          <td>
            <button class="btn btn-sm btn-primary" [routerLink]="'/appointments/detail/' + clinic.id">Appointment</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
