import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {DoctorsService} from "../../../services/doctors.service";
import {ActivatedRoute} from "@angular/router";
import {Doctor} from "../../../models/doctor.model";
import {PatientsService} from "../../../services/patients.service";
import {Patient} from "../../../models/patient.model";
import {AppointmentService} from "../../../services/appointment.service";
import {NgxSpinnerService} from "ngx-spinner";
import {SelectionType} from "@swimlane/ngx-datatable";
import {MatTableDataSource} from "@angular/material/table";
import {forkJoin} from "rxjs";

@Component({
    selector: 'app-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DetailsComponent implements OnInit {
    public selectedDoctor: Doctor;
    public doctors: Doctor[] = [];
    public unAssignedPatients = new MatTableDataSource<Patient>();
    public assignedPatients = new MatTableDataSource<Patient>();
    public clinicId: string = '';
    public selectedPatients: Patient[] = [];
    public selectedCountry = 'all';

    constructor(private doctorsService: DoctorsService,
                private patientsService: PatientsService,
                private appointmentService: AppointmentService,
                private spinnerService: NgxSpinnerService,
                private activatedRoute: ActivatedRoute) {
        this.clinicId = this.activatedRoute.snapshot.params['id'];
    }

    ngOnInit() {
        this.getAllClinic();
    }

    getAllClinic() {
        this.spinnerService.show();

        this.doctorsService.getDoctors(this.clinicId).subscribe((res: Doctor[]) => {
            this.doctors = res;
            void this.spinnerService.hide();
        }, (err) => {
            void this.spinnerService.hide();
        });
    }

    getPatients() {
        this.spinnerService.show();
        forkJoin([
                this.patientsService.getPatients(this.selectedDoctor.id, true, ''),
                this.patientsService.getPatients(this.selectedDoctor.id, false, this.selectedCountry)
            ])
            .subscribe((res) => {
                this.assignedPatients.data = res[0];
                this.unAssignedPatients.data = res[1];

                void this.spinnerService.hide();
            }, (err) => {
                void this.spinnerService.hide();
            })
    }

    selectDoctor(doctor: Doctor) {
        this.selectedDoctor = doctor;
        this.getPatients();
    }

    save() {
        void this.spinnerService.show();
        const body = {
            clinicId: this.clinicId,
            selectedPatients: this.selectedPatients,
            doctorId: this.selectedDoctor?.id
        }
        this.appointmentService.createAppointment(body).subscribe(_ => {
            this.getPatients();
            this.selectedPatients = [...[]];
            void this.spinnerService.hide();
        }, (err) => {
            void this.spinnerService.hide();
        });
    }

    removeAppointmentByDoctor(patient: Patient) {
        void this.spinnerService.show();
        this.appointmentService.deleteAppointment(this.selectedDoctor.id, patient.id).subscribe(_ => {
            this.getPatients();
            void this.spinnerService.hide();
        }, (err) => {
            void this.spinnerService.hide();
        });
    }

    selectPatients(patients: Patient[]) {
        this.selectedPatients = patients;
    }

    protected readonly SelectionType = SelectionType;
}
