<div class="row">
    <div *ngIf="doctors" class="col-12 mt-4 mb-4">
        <ngx-datatable class="material" [rows]="doctors" [columnMode]="'force'"
                       [headerHeight]="40" [footerHeight]="20" [rowHeight]="50"
                       [selectionType]="SelectionType.single" (select)="selectDoctor($event.selected[0])">
            <ngx-datatable-column name="Clinic Doctor Name">
                <ng-template let-doctor="row" ngx-datatable-cell-template>
                    {{ doctor.name.tr ? doctor.name.tr : doctor.name.en }}
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
    <hr>
    <div class="col-12 mb-4">
        <div class="row">
            <div class="col-8">
                <h2>UnAssigned</h2>
            </div>
            <div class="col-4">
                <div class="mb-2" *ngIf="selectedDoctor">
                    <label for="locationSelect">Bölge Seçin</label>
                    <select id="locationSelect" (change)="getPatients()" class="form-select" [(ngModel)]="selectedCountry">
                        <option value="all">Tüm Bölgeler</option>
                        <option value="istanbul">İstanbul</option>
                        <option value="ankara">Ankara</option>
                        <option value="antalya">Antalya</option>
                    </select>
                </div>
            </div>
        </div>
        <hr>
        <app-patient-table [hidden]="unAssignedPatients.data.length <= 0" (selectPatients$)="selectPatients($event)"
                           [dataSource]="unAssignedPatients" [selectedCountry]="selectedCountry"></app-patient-table>
        <button [disabled]="selectedPatients.length <= 0" (click)="save()" class="btn btn-success mt-2">Appointment
        </button>
    </div>
    <hr>
    <div class="col-12 mb-4">
        <h2>Assigned</h2>
        <hr>
        <app-patient-table [hidden]="assignedPatients.data.length <= 0" (removeAction$)="removeAppointmentByDoctor($event)" [dataSource]="assignedPatients"
                           [actions]="true"></app-patient-table>
    </div>
</div>
